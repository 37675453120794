<template>
  <div>
    <div class="home-bg1">
      <div class="content-a wr">
        <div class="w1520 f-cb">
          <div class="left">
            <div class="tit hnm lh1 wow fadeInUp animated animated animated" style="visibility: visible;">
              无线数据采集系统 (Wireless Data Acquisition System)
              <div class="title_divider" style="background: rgb(0, 59, 179);"></div>
            </div>
            <div class="tit-18 hnr t2 wow fadeInUp animated animated animated" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s;">
              <p style="line-height: 31.5px; text-align: justify;font-size: 18px;color:#fff;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  该系统具备强大的兼容性和扩展性，能够对接各类物联网设备和视频监控，支持 Socket（TCP、UDP）、MQTT、HTTP、OPC 等多种通信协议。它可与 ONENET、CTWing 等主流平台进行数据交互，
                  并支持字节流、XML、ASCII 码、JSON 等多种数据格式的解析与配置。此外，系统提供 JS 脚本工具，允许用户自定义驱动，同时开放 API 接口，支持第三方开发更复杂的驱动程序。设备管理
                  方面，支持设备型号功能，所有参数、显示效果、组态画面、报警和控制逻辑可一次性配置，并可自动继承到所有同类设备，实现高效批量管理。
                </span>
              </p><p><br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-b wr">
        <div class="w1520">
          <div class="tit-50 hnm lh1 wow fadeInUp animated animated animated" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s;">工作流程</div>
          <div class="list">
            <div class="li wow fadeInUp animated animated animated" style="visibility: visible;" v-for="(item,index) in functions" :key="index">
              <div class="txt">
                <div class="tit-32 mc lh1 hnb">{{item.subheading}}</div>
                <div class="tit-18">
                  <p style="text-align: justify; line-height: 28px;" v-for="(it,i) in item.content" :key="i"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">{{it}}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="content-b wr">
        <div class="w1520">
          <div class="left wow fadeInUp animated animated animated" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s;">
            <img src="../../img/fuwuBg.jpg" alt="总体概况">
          </div>
          <div class="right">
            <div class="tit-50 hnm lh1 wow fadeInUp animated animated animated" style="visibility: visible;">总体概况</div>
            <div class="tit-18 hnr t2 wow fadeInUp animated animated animated" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s;">
              <p style="line-height: 31.5px; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">迪倍特设备运维管理云平台（迪倍特巡检云）是集设备运维管理、数据储存、数据处理为一体的云计算平台，该平台通过对设备的巡检、点检、维修以及运行状态等数据信息进行收集、传输、储存、统计、分析、预测和维护，来提高设备工作效率和维护维修效率，系统自动生成相关任务、报表及设备运行报告，迪倍特以建设信息化设施设备运维系统，保障高效化安全生产体系目标，融合标准化、流程化、协作化、数字化、精细化管理理念，为企业打造全员参与的高校协同的设备运维管理SAAS平台。</span></p><p><br></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
          functions: [
            {
              subheading: '数据采集',
              content: [
                '传感器实时采集数据，并通过 MCU 处理后发送至无线通信模块',
              ]
            },
            {
              subheading: '数据传输',
              content: [
                '无线通信模块（Wi-Fi/LoRa/NB-IoT 等）将数据传输至网关或直接上传至云端',

              ]
            },
            {
              subheading: '数据处理',
              content: [
                '边缘计算（网关端）：初步过滤、压缩、分析数据',
                '云端计算：存储数据，进行大数据分析、建模和机器学习处理',
              ]
            },
            {
              subheading: '数据展示与控制',
              content: [
                '通过 Web、APP、控制系统显示数据趋势、报警信息',
                '远程控制设备，如调节温度、自动报警等',
              ]
            },
          ],
        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
  user-select: none;
  width: 100%;
  position: relative;
  overflow: auto;
  .content-a {
    background-image: url('../../img/coreTechnology3.jpg');
    background-size: cover;
    .w1520 {
      padding: 120px 0 16px;
      border-bottom: 1px solid #d5d6d6;
    }
    .left {
      width: 73%;
      float: left;
      .tit {
        font-weight: 500;
        color: #fff;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 34px;
       .title_divider {
          width: 40px;
          height: 6px;
          margin-top: 14px;
        } 
      }
      .describe {
        color: #fff;
        font-size: 20px;
        width: 80%;
        overflow: hidden;
        .txt { 
          margin-bottom: 10px;
          text-align: justify;
        }
      }
      .tit-50 {
        line-height: 1;
        font-size: 50px;
      }
      .t2 {
        margin-top: 1.25rem;
        line-height: 2;
      }
    }
    .right {
      width: 50%;
      padding-left: 126px;
      float: left;
    }
  }
  .content-b {
    padding: 60px 0;
    .list {
      margin-top: 49px;
      .li {
        width: calc(30%);
        height: 236px;
        border-radius: 5px;
        box-shadow: -1px 0px 12px rgba(0, 0, 0, 0.1);
        float: left;
        margin-right: 40px;
        display: flex;
        padding: 40px 20px;
        margin-bottom: 50px;
        transition: all 0.5s;
        padding-right: 40px;
        .txt {
          // width: calc(40%);
          padding-left: 12px;
          .tit-18 {
            font-size: 0;
            text-align: left;
            margin-top: 20px;
            margin-left: 20px;
            p {
              // display: inline-block;
              // width: 50%;
              font-size: 16px;
              line-height: 2;
              color: #666666;
              padding-left: 18px;
              transition: all 0.5s;
              position: relative;
            }
            p::after {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              background-color: #666;
              opacity: 0.5;
              border-radius: 50%;
              top: 10px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .content-c {
    padding: 60px 0;
    .left {
      width: 50%;
      padding-right: 126px;
      float: left;
    }
    .right {
      width: 50%;
      float: left;
      .tit-50 {
        line-height: 1;
        font-size: 50px;
      }
      .t2 {
        margin-top: 1.25rem;
        line-height: 2;
      }
    }
  }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        // margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>